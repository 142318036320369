import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class PermissionService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/permission`;

    }

    paginate(index = null) {
        let url = `${this.#api}`;
        if (index)
            url  = url +"?page="+index
        return apiService.get(url);
    }

    all() {
        let url = `${this.#api}/all/permissions`;
        return apiService.get(url);
    }


    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.put(url, data);

    }

    store(data) {
        let url = `${this.#api}/create`
        return apiService.post(url, data);

    }

    show(id) {
        let url = `${this.#api}/${id}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }

    // assignPermission(id) {
    //     let url = `${this.#api}/role/${id}/assign-permission`
    //     return apiService.post(url, data);
    // }
    // removePermission(id, data) {
    //     let url = `${this.#api}/role/${id}/remove-permission`
    //     return apiService.post(url, data);

    // }
    // rolePermissions(id) {
    //     let url = `${this.#api}/role/${id}/permissions`
    //     return apiService.get(url);
    // }

    getGroupedPermission(){
        let url = `${this.#api}/by/group`
        return apiService.get(url);
    }



}
